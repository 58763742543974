import type { ReactNode } from "react";
import { JsonView } from "react-json-view-lite";
import { jsonViewer } from "~/design/generated/recipes";

type JsonViewerProps = {
  json: any;
  expanded?: boolean;
  children?: ReactNode;
};

export function JsonViewer({ json, children, expanded = true }: JsonViewerProps) {
  const classes = jsonViewer({});

  return (
    <JsonView
      data={json || children}
      shouldExpandNode={() => expanded}
      style={{
        container: classes.container,
        basicChildStyle: classes.basicChildStyle,
        label: classes.label,
        punctuation: classes.punctuation,
        stringValue: classes.stringValue,
        booleanValue: classes.booleanValue,
        nullValue: classes.nullValue,
        numberValue: classes.numberValue,
        undefinedValue: classes.undefinedValue,
        otherValue: classes.otherValue,
        collapseIcon: classes.collapseIcon,
        collapsedContent: classes.collapsedContent,
        expandIcon: classes.expandIcon,
      }}
    />
  );
}

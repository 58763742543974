import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const jsonViewerDefaultVariants = {}
const jsonViewerCompoundVariants = []

const jsonViewerSlotNames = [
  [
    "container",
    "jsonViewer__container"
  ],
  [
    "basicChildStyle",
    "jsonViewer__basicChildStyle"
  ],
  [
    "label",
    "jsonViewer__label"
  ],
  [
    "punctuation",
    "jsonViewer__punctuation"
  ],
  [
    "stringValue",
    "jsonViewer__stringValue"
  ],
  [
    "booleanValue",
    "jsonViewer__booleanValue"
  ],
  [
    "nullValue",
    "jsonViewer__nullValue"
  ],
  [
    "numberValue",
    "jsonViewer__numberValue"
  ],
  [
    "undefinedValue",
    "jsonViewer__undefinedValue"
  ],
  [
    "otherValue",
    "jsonViewer__otherValue"
  ],
  [
    "collapseIcon",
    "jsonViewer__collapseIcon"
  ],
  [
    "collapsedContent",
    "jsonViewer__collapsedContent"
  ],
  [
    "expandIcon",
    "jsonViewer__expandIcon"
  ]
]
const jsonViewerSlotFns = /* @__PURE__ */ jsonViewerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, jsonViewerDefaultVariants, getSlotCompoundVariant(jsonViewerCompoundVariants, slotName))])

const jsonViewerFn = memo((props = {}) => {
  return Object.fromEntries(jsonViewerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const jsonViewerVariantKeys = []
const getVariantProps = (variants) => ({ ...jsonViewerDefaultVariants, ...compact(variants) })

export const jsonViewer = /* @__PURE__ */ Object.assign(jsonViewerFn, {
  __recipe__: false,
  __name__: 'jsonViewer',
  raw: (props) => props,
  variantKeys: jsonViewerVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, jsonViewerVariantKeys)
  },
  getVariantProps
})